import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private readonly tourKey = 'tourCompleted';
  private currentRoute: string = '';

  constructor(private shepherdService: ShepherdService, private route: ActivatedRoute, private router: Router) {}

  public startTour(path: string): void {
    this.shepherdService.defaultStepOptions = {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: true,
    };

    // Get the current route
    this.shepherdService.modal = true;

    this.currentRoute = path;

    // this.currentRoute = this.router.url;
    this.currentRoute = this.getPathType(this.currentRoute);
    // Determine which tour steps to show based on the route

    const tourSteps = this.getTourSteps(this.currentRoute);

    this.shepherdService.addSteps(tourSteps);

    this.shepherdService.tourObject.on('cancel', () => this.setTourCompleted());
    // Listen for when the tour is completed
    this.shepherdService.tourObject.on('complete', () => this.setTourCompleted());
    this.shepherdService.start();
  }

  // private getTourSteps(route: string): any {
  //   switch (route) {
  //     case '/home':
  //       return [
  //         {
  //           id: 'step1',
  //           title: '<strong>Welcome to Builtdesign!</strong>',
  //           text: 'This is your dashboard where you can manage all your activities. From tracking bids to overseeing ongoing projects, everything you need is right here.',
  //           attachTo: {
  //             element: '.step1',
  //             on: 'right',
  //           },
  //           buttons: [
  //             {
  //               text: 'Skip',
  //               secondary: 'true',
  //               action: () => this.shepherdService.complete(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step2',
  //           title: '<strong>Profile</strong>',
  //           text: ' Here, you can view and update your personal information, including your name, contact details and portfolio.',
  //           attachTo: {
  //             element: '.step2',
  //             on: 'right',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step3',
  //           title: '<strong>Explore Projects</strong>',
  //           text: 'This is where you can explore new projects and manage your applied projects.',
  //           attachTo: {
  //             element: '.step3',
  //             on: 'right',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step4',
  //           title: '<strong>My Projects</strong>',
  //           text: 'In the My Projects section, you’ll find all the Design Proposal Projects (DPP) you’re currently working on. ',
  //           scrollTo: false,
  //           attachTo: {
  //             element: '.step4',
  //             on: 'bottom-start',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step5',
  //           title: '<strong>My Jobs</strong>',
  //           text: 'The My Jobs section is where you manage all your ancillary service work. These are smaller tasks or freelance services that you’ve been hired to complete outside of the main competition-based projects.',
  //           scrollTo: false,
  //           attachTo: {
  //             element: '.step5',
  //             on: 'bottom-start',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step6',
  //           title: '<strong>Provide your valuable Feedback</strong>',
  //           text: 'Using this section you can provide your valuable feedback on where we can improve.',
  //           attachTo: {
  //             element: '.step6',
  //             on: 'bottom',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step7',
  //           title: '<strong>View Notifications</strong>',
  //           text: 'You can view various notifications here about various activities of yours.',
  //           attachTo: {
  //             element: '.step7',
  //             on: 'bottom',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'step8',
  //           title: '<strong>Customer Support</strong>',
  //           text: 'In case of any support you need we are here to help you. Please let us know it and our team member will contact you.',
  //           attachTo: {
  //             element: '.step8',
  //             on: 'bottom',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               secondary: true,
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Next',
  //               action: () => {
  //                 this.router.navigateByUrl('/project');
  //                 // this.shepherdService.show('project-step1');
  //                 this.shepherdService.hide();
  //               },
  //             },
  //           ],
  //         },
  //         {
  //           id: 'project-step1',
  //           title: 'Explore Projects',
  //           text: 'In the Explore Projects section, you can browse through a list of new and available projects that match your expertise.',
  //           scrollTo: false,
  //           attachTo: {
  //             element: '.project-step1',
  //             on: 'bottom-start',
  //           },
  //           buttons: [
  //             {
  //               text: 'Next',
  //               action: () => this.shepherdService.next(),
  //             },
  //           ],
  //         },
  //         {
  //           id: 'project-step2',
  //           title: 'This is applied projects section.',
  //           text: "In the Applied Projects section, you can track the status of the projects you've applied to.",
  //           scrollTo: false,
  //           attachTo: {
  //             element: '.project-step2',
  //             on: 'bottom-start',
  //           },
  //           buttons: [
  //             {
  //               text: 'Back',
  //               action: () => this.shepherdService.back(),
  //             },
  //             {
  //               text: 'Finish',
  //               action: () => {
  //                 this.shepherdService.complete();
  //                 this.router.navigate(['/home']);
  //                 this.setTourCompleted();
  //               },
  //             },
  //           ],
  //         },
  //       ];

  //     default:
  //       return []; // No tour for unknown routes
  //   }
  // }

  private getTourSteps(route: string): any {
    switch (route) {
      case '/home':
        return [
          {
            id: 'step1',
            title: '<strong>Welcome to Builtdesign!</strong>',
            text: 'Track your project status and activities here, like <strong>bids</strong>, <strong>ongoing projects</strong>, and <strong>proposals</strong>. Hover over the question mark icons for more details.',
            attachTo: {
              element: '.step1',
              on: 'left',
            },
            buttons: [
              {
                text: 'Skip',
                secondary: 'true',
                action: () => this.shepherdService.complete(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step2',
            title: '<strong>Badges</strong>',
            text: 'This section highlights badges that boost your success on the platform. Click the <strong>How to get badges</strong> link to learn about earning badges. Your earned badges will be displayed here.',
            attachTo: {
              element: '.step2',
              on: 'right',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step3',
            title: '<strong>My Projects & My Jobs</strong>',
            text: `
              <p>In this section, view your active projects or jobs. Switch between the <strong>My Projects</strong> and <strong>My Jobs</strong> tabs to check their status and details.</p>
              <img src="https://s3-ap-south-1.amazonaws.com/project-content.builtdesign.in/2024/12/30/screenshot_2024-12-30_153105.png" alt="Profile Completion" style="max-width: 100%; height: auto; margin-top: 10px;">
            `,
            scrollTo: false,
            attachTo: {
              element: '.step3',
              on: 'right',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step4',
            title: '<strong>Profile Section</strong>',
            text: 'The Profile section shows your completion score. A fully completed profile increases your chances of acceptance on the platform. Aim for 100% to stand out to clients.',
            scrollTo: false,
            attachTo: {
              element: '.step4',
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step5',
            title: '<strong>Sidebar</strong>',
            text: `
    <strong>Dashboard (<span nz-icon="" nztype="appstore" nztheme="outline" class="anticon sidebar-icons anticon-appstore" ng-reflect-nz-type="appstore" ng-reflect-nz-theme="outline"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="appstore" aria-hidden="true"><path d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"></path></svg></span>)</strong>: current page where you can track your activities and metrics.<br>
    <strong>Profile (<span nz-icon="" nztype="user" nztheme="outline" class="anticon sidebar-icons anticon-user" ng-reflect-nz-type="user" ng-reflect-nz-theme="outline"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="user" aria-hidden="true"><path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path></svg></span>)</strong>: View and update your profile to improve your score and attract more clients.<br>
    <strong>Explore Projects (<span nz-icon="" nztype="globe" nztheme="outline" class="anticon sidebar-icons anticon-globe" ng-reflect-nz-type="globe" ng-reflect-nz-theme="outline"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="Globe" fill="currentColor" width="1em" height="1em" data-icon="globe" aria-hidden="true"><path d="M30.118 21.638c-5.446 5.444-14.308 5.448-19.754 0-2.636-2.634-4.086-6.142-4.086-9.878s1.452-7.244 4.084-9.876A1 1 0 0 0 8.948.47c-3.012 3.01-4.67 7.02-4.67 11.29s1.66 8.282 4.67 11.292c2.542 2.544 5.738 4.03 9.052 4.496V30h-3a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2h-3v-2.288c.08.002.16.012.24.012a15.92 15.92 0 0 0 11.292-4.67 1 1 0 0 0-1.414-1.416zM20.24 0C13.744 0 8.48 5.266 8.48 11.76s5.266 11.76 11.76 11.76S32 18.256 32 11.76 26.734 0 20.24 0zm0 21.52c-5.382 0-9.76-4.378-9.76-9.76S14.858 2 20.24 2 30 6.378 30 11.76s-4.378 9.76-9.76 9.76z"></path></svg></span>)</strong>: Browse available projects to find opportunities.
  `,
            scrollTo: false,
            attachTo: {
              element: '.step5',
              on: 'right', // this positions the step on the right
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: `${this.isTourCompleted() ? 'Finish' : 'Next'}`,
                action: () => {
                  !this.isTourCompleted() && this.router.navigateByUrl('/project');
                  // this.shepherdService.show('project-step1');
                  this.shepherdService.hide();
                },
              },
            ],
          },
          // {
          //   id: 'step6',
          //   title: '<strong>Provide your valuable Feedback</strong>',
          //   text: 'Using this section you can provide your valuable feedback on where we can improve.',
          //   attachTo: {
          //     element: '.step6',
          //     on: 'bottom',
          //   },
          //   buttons: [
          //     {
          //       text: 'Back',
          //       secondary: true,
          //       action: () => this.shepherdService.back(),
          //     },
          //     {
          //       text: 'Next',
          //       action: () => this.shepherdService.next(),
          //     },
          //   ],
          // },
          // {
          //   id: 'step7',
          //   title: '<strong>View Notifications</strong>',
          //   text: 'You can view various notifications here about various activities of yours.',
          //   attachTo: {
          //     element: '.step7',
          //     on: 'bottom',
          //   },
          //   buttons: [
          //     {
          //       text: 'Back',
          //       secondary: true,
          //       action: () => this.shepherdService.back(),
          //     },
          //     {
          //       text: 'Next',
          //       action: () => this.shepherdService.next(),
          //     },
          //   ],
          // },
          // {
          //   id: 'step8',
          //   title: '<strong>Customer Support</strong>',
          //   text: 'In case of any support you need we are here to help you. Please let us know it and our team member will contact you.',
          //   attachTo: {
          //     element: '.step8',
          //     on: 'bottom',
          //   },
          //   buttons: [
          //     {
          //       text: 'Back',
          //       secondary: true,
          //       action: () => this.shepherdService.back(),
          //     },
          //     {
          //       text: 'Next',
          //       action: () => {
          //         this.router.navigateByUrl('/project');
          //         // this.shepherdService.show('project-step1');
          //         this.shepherdService.hide();
          //       },
          //     },
          //   ],
          // },

          // {
          //   id: 'project-step2',
          //   title: 'This is applied projects section.',
          //   text: "In the Applied Projects section, you can track the status of the projects you've applied to.",
          //   scrollTo: false,
          //   attachTo: {
          //     element: '.project-step2',
          //     on: 'bottom-start',
          //   },
          //   buttons: [
          //     {
          //       text: 'Back',
          //       action: () => this.shepherdService.back(),
          //     },
          //     {
          //       text: 'Finish',
          //       action: () => {
          //         this.shepherdService.complete();
          //         this.router.navigate(['/home']);
          //         this.setTourCompleted();
          //       },
          //     },
          //   ],
          // },
        ];

      case '/project':
        return [
          {
            id: 'project-step1',
            title: '<strong>Explore Projects</strong>',
            text: `<strong>Explore Projects</strong>: Browse available projects and find opportunities that match your skills. Click <strong>"Apply"</strong> for further details.<br>
                    <br/>
                   <strong>Applied Projects</strong>: View the projects you've already applied for and track their progress.`,
            scrollTo: false,
            attachTo: {
              element: '.project-step1',
              on: 'top',
            },
            buttons: [
              ...(!this.isTourCompleted()
                ? [
                    {
                      text: 'Back',
                      secondary: true,
                      action: () => {
                        this.router.navigateByUrl('/home');
                        this.shepherdService.hide();
                      },
                    },
                  ]
                : [
                    {
                      text: 'Skip',
                      secondary: 'true',
                      action: () => this.shepherdService.complete(),
                    },
                  ]),
              {
                text: `${this.isTourCompleted() ? 'Finish' : 'Next'}`,
                action: () => {
                  !this.isTourCompleted() && this.router.navigate([`/project/${localStorage.getItem('jobId')}`]);
                  this.shepherdService.hide();
                },
              },
            ],
          },
        ];

      case '/project-dpp':
        return [
          {
            id: 'project-details',
            title: '<strong>Project description!</strong>',
            text: 'View specifics about the project, including type, scope, and other essential information. Scroll to the bottom to ensure you don’t miss any details.',
            attachTo: {
              element: '.project-details',
              on: 'top',
            },
            buttons: [
              ...(!this.isTourCompleted()
                ? [
                    {
                      text: 'Back',
                      secondary: true,
                      action: () => {
                        this.router.navigateByUrl('/project');
                        this.shepherdService.hide();
                      },
                    },
                  ]
                : [
                    {
                      text: 'Skip',
                      secondary: 'true',
                      action: () => this.shepherdService.complete(),
                    },
                  ]),
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'project-brief',
            title: '<strong>Project Brief!</strong>',
            text: 'Explore detailed descriptions of the project, including requirements and aspects for each room or area.',
            attachTo: {
              element: '.project-brief',
              on: 'top',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'reference-files',
            title: '<strong>Reference Files!</strong>',
            text: 'Access files provided by the client, showcasing their inspirations and preferences.',
            attachTo: {
              element: '.reference-files',
              on: 'top',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'project-wall',
            title: '<strong>Project Wall!</strong>',
            text: 'Post your queries or read discussions and questions shared by co-applicants to stay informed.',
            attachTo: {
              element: '.project-wall',
              on: 'top',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Finish',
                action: () => {
                  !this.isTourCompleted() && this.router.navigate(['/home']);
                  this.shepherdService.complete();
                  this.setTourCompleted();
                },
              },
            ],
          },
        ];

      case '/project-ancl':
        return [
          {
            id: 'project-detail-anc',
            title: '<strong>Project Details!</strong>',
            text: 'View specifics about the project, including type, scope, and other essential information. Scroll to the bottom to ensure you don’t miss any details.',
            attachTo: {
              element: '.project-details-anc',
              on: 'top',
            },
            buttons: [
              ...(!this.isTourCompleted()
                ? [
                    {
                      text: 'Back',
                      secondary: true,
                      action: () => {
                        this.router.navigateByUrl('/project');
                        this.shepherdService.hide();
                      },
                    },
                  ]
                : [
                    {
                      text: 'Skip',
                      secondary: 'true',
                      action: () => this.shepherdService.complete(),
                    },
                  ]),
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'project-description-anc',
            title: '<strong>Project Description!</strong>',
            text: 'Explore detailed descriptions of the project, including requirements and aspects for each room or area.',
            attachTo: {
              element: '.project-description-anc',
              on: 'top',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Finish',
                action: () => {
                  !this.isTourCompleted() && this.router.navigate(['/home']);
                  this.shepherdService.complete();
                  this.setTourCompleted();
                },
              },
            ],
          },
        ];

      default:
        return []; // No tour for unknown routes
    }
  }

  private setTourCompleted(): void {
    localStorage.setItem(this.tourKey, 'true');
  }

  public isTourCompleted(): boolean {
    return localStorage.getItem(this.tourKey) === 'true';
  }

  private getPathType(path: string): string {
    switch (true) {
      case /^\/home$/.test(path):
        return '/home';

      case /^\/project$/.test(path):
        return '/project';

      case /^\/project\/[^/]+$/.test(path):
        return localStorage.getItem('isDpp') === 'true' ? '/project-dpp' : '/project-ancl';

      default:
        return '';
    }
  }
}
